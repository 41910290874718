<template>
  <!-- 
    Can't use component to generate router-link 
    as 'to' does not get appended correctly resulting in 
    missing href=""
  -->
  <router-link
    v-if="isLink && !aTag"
    :to="to"
    :target="isLink ? target : null"
    :download="download"
    class="tw-space-x-1"
    :class="isLink && linkClass"
  >
    <slot />
  </router-link>

  <component
    :is="linkType"
    v-else
    :href="isLink && aTag ? to : null"
    :target="isLink ? target : null"
    :download="download"
    class="tw-space-x-1"
    :class="isLink && linkClass"
  >
    <slot />
  </component>
</template>

<script>
import { isString } from '@helpers/utils.js'
import { computed, toRefs } from 'vue'

export default {
  props: {
    isLink: {
      type: Boolean,
      default: true,
    },
    linkPrimary: {
      type: Boolean,
      default: true,
    },
    hoverPrimary: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: () => ({}),
    },
    target: {
      type: String,
      default: null,
      validator: function (value) {
        return ['_self', '_blank'].indexOf(value) !== -1
      },
    },
    download: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { isLink, to, linkPrimary, hoverPrimary } = toRefs(props)

    const aTag = computed(() => isLink.value && isString(to.value))

    const linkType = computed(() => (aTag.value ? 'a' : 'span'))

    const linkClass = computed(() => {
      const classList =
        'tw-button-transition tw-cursor-pointer tw-outline-none tw-border-none'
      if (hoverPrimary.value) {
        return `${classList} hover:game-link-color focus:game-link-color`
      }
      return linkPrimary.value
        ? `${classList} game-link-color hover:game-link-color-hover focus:game-link-color-hover`
        : classList
    })

    return {
      linkClass,
      linkType,
      aTag,
    }
  },
}
</script>
