<template>
  <div
    class="tw-flex tw-items-center tw-justify-center tw-px-3 tw-bg-theme-3 tw-text-theme tw-global--border-radius tw-h-8 tw-whitespace-nowrap tw-ml-2 sm:tw-ml-4"
  >
    <div
      class="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-sm"
    >
      <base-button basic-btn text-link-hover primary icon="angle-up">
        back to top
      </base-button>
    </div>
  </div>
</template>
