<template>
  <div class="tw-flex tw-flex-col tw-animate-pulse">
    <loading-label v-if="label" :alt-bg="altBg"></loading-label>

    <div
      class="tw-flex tw-items-center tw-w-full tw-rounded tw-space-x-2"
      :class="[
        inputBgStyles,
        background ? 'tw-px-4 tw-input--height ' : 'tw-pt-1',
      ]"
    >
      <div
        class="tw-flex tw-shrink-0 tw-relative tw-left-0 tw-h-3 tw-w-3 tw-rounded-sm tw-items-center tw-justify-center"
        :class="[inputCheckboxBgStyles]"
      ></div>
      <div
        class="tw-w-20 tw-h-2 tw-rounded-full"
        :class="[inputCheckboxBgStyles]"
      ></div>
    </div>
  </div>
</template>

<script>
import LoadingLabel from '@components/Loading/LoadingLabel.vue'
import { toRefs, computed } from 'vue'
export default {
  components: {
    LoadingLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    background: {
      type: Boolean,
      default: true,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { background, altBg } = toRefs(props)

    const inputBgStyles = computed(() =>
      background.value ? 'game-tile-background-color' : ''
    )

    const inputCheckboxBgStyles = computed(() => {
      if (altBg.value) {
        return 'tw-bg-theme'
      }

      return background.value ? 'tw-bg-theme' : 'game-tile-background-color'
    })

    return {
      inputCheckboxBgStyles,
      inputBgStyles,
    }
  },
}
</script>
