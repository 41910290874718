<template>
  <small :id="id" class="tw-block tw-opacity-50" :class="textAlign">
    <slot />
  </small>
</template>
<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
    },
  },
  setup(props) {
    const { align } = toRefs(props)

    const textAlign = computed(() => {
      if (align.value === 'center') {
        return 'tw-text-center'
      } else if (align.value === 'right') {
        return 'tw-text-right'
      } else {
        return 'tw-text-left'
      }
    })

    return { textAlign }
  },
}
</script>
