<template>
  <p v-if="text" class="tw-text-xs">
    {{ text }}
  </p>
</template>

<script>
import { toRefs, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { count } = toRefs(props)
    const { t } = useI18n()

    const text = computed(() => {
      if (props.min > 0 && count.value < props.min) {
        const req = props.min - count.value
        return t('form_not_enough_chars', { count: req })
      } else if (props.max > 0 && count.value <= props.max) {
        const rem = props.max - count.value
        return t('form_chars_remaining', { count: rem })
      }

      return ''
    })

    return {
      text,
    }
  },
}
</script>
