<template>
  <loading-checkbox
    v-if="inputLoading"
    :label="topLabel"
    :background="background"
    :alt-bg="altBg"
  ></loading-checkbox>

  <div v-else class="tw-flex tw-flex-col" :class="{ 'tw-w-full': optionList }">
    <div v-if="topLabel" class="tw-flex tw-label-gap">
      <base-input-label
        v-if="topLabel"
        :id-for="inputId"
        :required="required"
        :label="topLabel"
        :label-between="labelBetween"
      ></base-input-label>
    </div>

    <label
      class="tw-flex tw-items-center tw-group tw-button-transition tw-relative"
      :class="[
        customLabelStyles,
        inputBgStyles,
        disabled ? 'tw-cursor-default tw-opacity-75' : 'tw-cursor-pointer',
        small ? 'tw-text-sm' : 'tw-input--text-size',
        { 'tw-input--px  tw-input--min-height': background },
        {
          'tw-justify-center tw-input--height tw-w-full tw-font-bold tw-input--px':
            optionList,
        },
        { 'tw-capitalize': capsCase },
        { 'tw-global--border-radius': !noRadius },
        errorStyles,
      ]"
      @keydown="handleKeyPress"
    >
      <!-- hidden input is used for tabing / focus -->
      <input
        class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0"
        :checked="checked"
        type="checkbox"
        :tabindex="disabled ? '-1' : '0'"
        @click="updateCheckbox"
      />
      <span
        class="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-button-transition"
        :class="[
          checkStyles,
          hasHover,
          optionList
            ? 'tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-global--border-radius tw-z-0'
            : 'tw-h-3 tw-w-3 tw-rounded-sm tw-mr-2',
        ]"
      >
        <font-awesome-icon
          v-if="checked && !optionList"
          icon="check"
          class="tw-w-2 tw-h-2"
        ></font-awesome-icon>
      </span>

      <slot v-if="$slots.label" name="label" />
      <span v-else-if="capsCase" class="tw-z-1">{{ label.toLowerCase() }}</span>
      <span v-else class="tw-z-1">{{ label }}</span>

      <span
        v-if="append !== undefined"
        class="tw-ml-auto tw-pl-1 tw-text-right tw-opacity-50"
      >
        {{ append }}
      </span>
    </label>

    <div
      v-if="hasErrors || hint"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import LoadingCheckbox from '@components/Loading/LoadingCheckbox.vue'
import { genHtmlId, isBoolean, isObjEmpty } from '@helpers/utils.js'
import { computed, toRefs } from 'vue'

export default {
  components: { LoadingCheckbox },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    append: {
      type: [String, Number],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    topLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: null,
    },
    background: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    capsCase: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
    noRadius: {
      type: Boolean,
      default: false,
    },
    customLabelStyles: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      background,
      modelValue,
      optionList,
      disabled,
      sidebar,
      darkBg,
      errors,
      altBg,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()

    const checked = computed(
      () => modelValue.value === 1 || modelValue.value === true
    )

    const hasErrors = computed(() => !isObjEmpty(errors.value))

    const errorStyles = computed(() => {
      if (hasErrors.value) {
        return background.value
          ? 'tw-border-danger tw-border-2'
          : 'tw-text-danger'
      }
      return background.value && !disabled.value ? 'tw-input--focus-within' : ''
    })

    const hasHover = computed(() => {
      if (disabled.value || checked.value) {
        return ''
      }

      if (altBg.value) {
        return background.value ? '' : 'game-input-background-color'
      } else if (darkBg.value) {
        return ''
      } else {
        return background.value ? '' : 'game-input-background-color'
      }
    })

    const inputBgStyles = computed(() => {
      return background.value
        ? {
            'game-input-background-color-hover': !disabled.value,
            'game-tile-background-color': disabled.value,
          }
        : [
            'tw-opacity-90 hover:tw-opacity-100 focus-within:tw-opacity-100',
            {
              'focus-within:game-link-color':
                !optionList.value && !disabled.value,
            },
          ]
    })

    const checkStyles = computed(() => {
      if (checked.value) {
        return 'game-primary-button-color game-primary-text'
      }
      if (altBg.value) {
        return background.value
          ? ''
          : `${
              sidebar.value ? 'tw-mobile-grey' : 'tw-bg-theme'
            } tw-opacity-40 group-hover:tw-opacity-100 group-focus:tw-opacity-100`
      } else if (darkBg.value) {
        return background.value
          ? ''
          : 'game-primary-button-color tw-opacity-70 group-hover:tw-opacity-100 group-focus:tw-opacity-100'
      } else {
        return background.value ? 'tw-bg-theme' : 'game-tile-background-color'
      }
    })

    function updateCheckbox() {
      if (!disabled.value) {
        isBoolean(modelValue.value)
          ? emit('input', modelValue.value ? 0 : 1)
          : emit('input', modelValue.value ? false : true)
      }
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          break
        case 13: //return
        case 32: //space
          e.preventDefault()
          updateCheckbox()

          break
      }
    }

    return {
      handleKeyPress,
      updateCheckbox,
      inputBgStyles,
      checkStyles,
      errorStyles,
      hasErrors,
      hasHover,
      checked,
      inputId,
    }
  },
}
</script>
