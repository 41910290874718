import { cleanHTMLEntities } from '@helpers/utils.js'

function decode(element, binding) {
  element.innerText = cleanHTMLEntities(binding.value)
}

export default function (app) {
  app.directive('decode', {
    created: decode,
    updated: decode,
  })
}
