import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faDiscord,
  faFacebook,
  faFacebookSquare,
  faGithubSquare,
  faGoogle,
  faItchIo,
  faRedditAlien,
  faSteamSymbol,
  faTwitter,
  faTwitterSquare,
  faXbox,
  faYoutube,
  faGithub,
  faPaypal,
} from '@fortawesome/free-brands-svg-icons'

import {
  faEdit as farEdit,
  faEye as farEye,
  faMoon as farMoon,
} from '@fortawesome/free-regular-svg-icons'

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloudDownloadAlt,
  faCode,
  faCog,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileUpload,
  faFilter,
  faFlag,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  faKey,
  faLink,
  faList,
  faLock,
  faQuestionCircle,
  faRedoAlt,
  faSearch,
  faShareAlt,
  faSignOutAlt,
  faStar,
  faTag,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'

const brandIcons = [
  faDiscord,
  faFacebook,
  faFacebookSquare,
  faGithubSquare,
  faGoogle,
  faItchIo,
  faRedditAlien,
  faSteamSymbol,
  faTwitter,
  faTwitterSquare,
  faXbox,
  faYoutube,
  faGithub,
  faPaypal,
]

const regularIcons = [farEdit, farEye, farMoon]

const solidIcons = [
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloudDownloadAlt,
  faCode,
  faCog,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileUpload,
  faFilter,
  faFlag,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  faKey,
  faLink,
  faList,
  faLock,
  faQuestionCircle,
  faRedoAlt,
  faSearch,
  faShareAlt,
  faSignOutAlt,
  faStar,
  faTag,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faUsers,
]

export const installedIcons = [
  ...brandIcons.map((icon) => ['fab', icon.iconName]),
  ...solidIcons.map((icon) => icon.iconName),
  ...regularIcons.map((icon) => ['far', icon.iconName]),
]

library.add(...brandIcons, ...solidIcons, ...regularIcons)

export default FontAwesomeIcon
