<template>
  <!-- danger -->
  <div class="icon" v-html="html" />
</template>
<script>
import { ref } from 'vue'
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const html = ref('')

    async function load() {
      html.value = (await import(`../../icons/${props.icon}.svg?raw`)).default
    }
    load()

    return { html }
  },
}
</script>
<style scoped>
.icon >>> svg {
  width: 100%;
  height: 100%;
}
</style>
