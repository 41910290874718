<template>
  <!-- removing 'theming classes' will cause style issue throughout the site -->
  <main ref="mainWrap" class="tw-w-full">
    <modio-toast />
    <div
      ref="container"
      class="tw-flex tw-flex-col tw-h-full tw-w-full tw-relative tw-overflow-x-hidden tw-text-theme"
    >
      <router-view :key="$route.path" v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
    </div>
    <global-modals />
  </main>
</template>

<script>
import { gameStore, authStore, headerStore, localModStore } from '@stores'
import { getPublicGameRequest, updateLocalStorage } from '@services'
import ViewContainer from '@components/View/ViewContainer.vue'
import ModioToast from '@components/Message/ModioToast.vue'
import GlobalModals from '@views/layouts/GlobalModals.vue'
import * as sdkInterface from '@plugins/sdkInterface.js'
import { GAME_ID, HOST_URL } from '@config/game.js'
import webInterface from '@plugins/webInterface.js'
import { onMounted, nextTick, ref } from 'vue'
import { SCROLL_TO_ID } from '@config'
import {
  useWindowEvents,
  useQueryParams,
  useBoundaries,
  useTheming,
  useAsync,
} from '@composables'
export default {
  components: {
    ViewContainer,
    GlobalModals,
    ModioToast,
  },
  setup() {
    const { watchTheme } = useTheming()
    const { setBoundaryVars } = useBoundaries()
    const container = ref(null)
    const mainWrap = ref(null)
    const { addPublicGame } = gameStore()
    const { setToken } = authStore()
    const { setPortal, setPlatform } = headerStore()
    const { setLocalMods } = localModStore()

    const {
      data: gameData,
      run: runFetchGame,
      error: gameErrors,
    } = useAsync(() => getPublicGameRequest(GAME_ID))

    // watch for route changes to
    // set correct theming options
    watchTheme()

    useWindowEvents()
    useQueryParams()

    onMounted(async () => {
      if (location.pathname !== '/') {
        location.pathname = '/'
        return
      }

      _setWrapVars()
      resizeContainer()

      // Fetch language first so correct headers are sent
      await fetchLanguageFromSdk()

      fetchTokenFromSdk()
      fetchPortalFromSdk()
      fetchPlatformFromSdk()
      fetchLocalModsFromSdk()
      fetchGame()
    })

    async function fetchGame() {
      await runFetchGame()

      if (!gameErrors.value) {
        addPublicGame(gameData.value)
      }
    }

    async function fetchTokenFromSdk() {
      const token = await sdkInterface.getAuthToken()
      if (token) {
        setToken(token)
        updateLocalStorage()
      }
    }

    async function fetchLocalModsFromSdk() {
      const localMods = await sdkInterface.getLocalMods()
      if (localMods) {
        setLocalMods(localMods)
      }
    }

    async function fetchPortalFromSdk() {
      const portal = await sdkInterface.getPortal()
      if (portal) {
        setPortal(portal)
      }
    }

    async function fetchPlatformFromSdk() {
      const platform = await sdkInterface.getPlatform()
      if (platform) {
        setPlatform(platform)
      } else {
        setPlatform('windows')
      }
    }

    async function fetchLanguageFromSdk() {
      const lang = await sdkInterface.getLanguage()
      if (lang) {
        webInterface.setLanguage(lang)
      }
    }

    function onResize() {
      if (!container.value) return

      window.parent.postMessage(
        {
          height: container.value.scrollHeight,
          modioHeight: container.value.scrollHeight,
        },
        HOST_URL
      )
    }

    function resizeContainer() {
      new ResizeObserver(onResize).observe(container.value)
      setTimeout(onResize, 1000)
    }

    function _setWrapVars() {
      // wait for DOM to refresh for mainWrap to be set
      nextTick(() => {
        setBoundaryVars('main', mainWrap.value.getBoundingClientRect())
      })
    }

    return {
      SCROLL_TO_ID,
      container,
      mainWrap,
    }
  },
}
</script>
