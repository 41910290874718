import FontAwesomeIcon from '@plugins/fontawesome.js'
import directives from '@plugins/directives.js'
import App from '@views/layouts/ModioApp.vue'
import i18n from '@plugins/localisation.js'
import '@plugins/webInterface.js'
import { createApp } from 'vue'
import router from '@router'

// style imports
import 'highlight.js/styles/github-dark-dimmed.css'
import './sass/app.scss'
import './css/app.css'

const app = createApp(App)

app.use(router)
app.use(i18n)

/**
 * Autoloads components:
 * Base components
 */
const files = import.meta.glob('./components/Base/*.vue', { eager: true })
for (const key in files) {
  const name = key.split('/').pop().split('.')[0]
  app.component(name, files[key].default)
}

app.component('FontAwesomeIcon', FontAwesomeIcon)

directives(app)

app.mount('#app')
