<template>
  <div v-if="isLoading || isSuccess || isError">
    <font-awesome-icon
      v-if="isLoading"
      icon="cog"
      :size="size"
      class="tw-animate-spin-slow"
      :class="[icon, iconOpacity]"
    />
    <font-awesome-icon
      v-if="isSuccess"
      icon="check"
      :size="size"
      :class="[icon, iconOpacity]"
    />
    <font-awesome-icon
      v-if="isError"
      icon="exclamation-triangle"
      :size="size"
      :class="[icon, iconOpacity]"
    />
  </div>
</template>
<script>
import { useStatus } from '@composables'
import { computed, toRefs } from 'vue'
export default {
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    status: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { watchStatusAnimation } = useStatus()
    const { status } = toRefs(props)

    const { isLoading, isSuccess, isError, isAnimating } =
      watchStatusAnimation(status)

    const icon =
      'tw-fill-current tw-transition tw-duration-250 tw-ease-in-out tw-relative'

    const iconOpacity = computed(() =>
      isAnimating.value ? 'tw-opacity-1' : 'tw-opacity-0'
    )

    return {
      iconOpacity,
      isLoading,
      isSuccess,
      isError,
      icon,
    }
  },
}
</script>
